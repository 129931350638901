@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Signika:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Radio+Canada:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background: none;
  border-radius: 10px;
}





.c-dashboardInfo {
    margin-bottom: 15px;
  }
  .c-dashboardInfo .wrap {
    background: #ffffff;
    box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 5px 25px 15px;
    height: 100%;
  }
  .c-dashboardInfo__title,
  .c-dashboardInfo__subInfo {
    color: #6c6c6c;
    font-size: 1.18em;
  }
  .c-dashboardInfo span {
    display: block;
  }
  .c-dashboardInfo__count {
    font-weight: 600;
    font-size: 2.5em;
    line-height: 20px;
    color: #323c43;
  }
  .c-dashboardInfo .wrap:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    content: "";
  }

  .c-dashboardInfo:nth-child(1) .wrap:after {
    background: linear-gradient(82.59deg, #00c48c 0%, #00a173 100%);
  }
  .c-dashboardInfo:nth-child(2) .wrap:after {
    background: linear-gradient(81.67deg, #0084f4 0%, #1a4da2 100%);
  }
  .c-dashboardInfo:nth-child(3) .wrap:after {
    background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
  }
  .c-dashboardInfo:nth-child(4) .wrap:after {
    background: linear-gradient(81.67deg, #ff647c 0%, #1f5dc5 100%);
  }
  .c-dashboardInfo__title svg {
    color: #d7d7d7;
    margin-left: 5px;
  }
  .MuiSvgIcon-root-19 {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    -webkit-user-select: none;
            user-select: none;
    flex-shrink: 0;
  }



  /* new section block */




.section-card {
  padding: 0 30px;
}
.section-card .card {
  margin: 2px 0px;
  max-width: 10px;
  border-radius: 10px;
  overflow: hidden;
  background-image: linear-gradient(
    120deg,
    #132936,
    #132936 20%,
    #2196f3 20.5%,
    #2196f3
  );
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
}
.section-card .card .img-container {
  padding: 10px 0 0 0;
}
.section-card .card .img-container img {
  -webkit-filter: drop-shadow(0px -5px 25px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(0px -5px 25px rgba(0, 0, 0, 0.7));
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}
.section-card .card .infos {
  padding: 5px 5px;
  text-align: center;
}

.section-card .card .infos .name,
.section-card .card .infos .price {
  color: #fff;
}
.section-card .card .infos .name {
  font-size: 1.1rem;
}
.section-card .card .infos .price {
  margin: 10px 0 15px 0;
  font-size: 2rem;
}
.section-card .card .infos .btn {
  font-family: "Radio Canada", sans-serif;
  padding: 0.8rem 1.6rem;
  border-radius: 50px;
  border: 1px solid #f2f2f2;
  font-size: 1rem;
  font-weight: 300;
  min-width: 150px;
  cursor: pointer;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  background-color: #f2f2f2;
  transition: all 0.2s ease-in;
}
.section-card .card .infos .btn:hover {
  background-color: transparent;
  color: #fff;
  box-shadow: none;
}

@media screen and (min-width: 700px) {
  .section-card .card {
    display: flex;
    align-items: center;
    min-width: 180px;
    padding: 0px 45px;
  }
  .section-card .card .img-container {
    padding-top: 0;
    width: 60%;
  }
}

/* jhgfkjfkgk */

.spinner-box {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }


.circle-border {
    width: 150px;
    height: 150px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgb(63,249,220);
    background: linear-gradient(0deg, rgba(63,249,220,0.1) 33%, rgba(63,249,220,1) 100%);
    -webkit-animation: spin .8s linear 0s infinite;
            animation: spin .8s linear 0s infinite;
  }

  .circle-core {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 50%;
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    to{
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  @keyframes spin {
    from {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    to{
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }

  /* loaoing small */

  .spinner-box-small {
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }


.circle-border-small{
    width: 15px;
    height: 15px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgb(63,249,220);
    background: linear-gradient(0deg, rgba(63,249,220,0.1) 33%, rgba(63,249,220,1) 100%);
    -webkit-animation: spin .8s linear 0s infinite;
            animation: spin .8s linear 0s infinite;
  }

  .circle-core-small {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 50%;
  }
  @keyframes spin {
    from {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    to{
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }

  /* start container */

.start-container{
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: hidden;
    position: relative;
    border-radius: 16px;
    z-index: 0;
    box-shadow: none;
    text-align: center;
    background-color: #F0F8FF;
    width: 90%;
}
.start-container h4{
    font-size: 1.5rem;
    margin: 10px 0px;
}

@media (min-width: 900px){
.css-16ffwbg {
    padding: 0px 0px 0px 40px;
}
.css-1n3d5g8 {
    height: 100%;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: space-between;
}
}
.css-1n3d5g8 {
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: hidden;
    position: relative;
    border-radius: 16px;
    z-index: 0;
    box-shadow: none;
    text-align: center;
    background-color: #F0F8FF;
}
.css-16ffwbg {
    padding: 24px;
    color: rgb(33, 43, 54);
}

@media (min-width: 900px){
    .start-container{
        height: 25%;
        margin: 20px;
        display: flex;
        text-align: left;
        align-items: center;
        justify-content: space-between;
    }
}

@media (min-width: 0px){
.css-1v6tuzf {
    padding-bottom: 2px;
}
}
.css-1v6tuzf {
    margin: 0px auto;
    line-height: 1.57143;
    font-size: 0.875rem;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    max-width: 480px;
}

/* avtar */
.css-1pv7ovc {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 12px;
    background-color: #F0F8FF;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin: 10px;
}

.css-inlwhr {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    font-family: "Public Sans", sans-serif;
    font-size: 1.25rem;
    line-height: 1;
    border-radius: 50%;
    overflow: hidden;
    -webkit-user-select: none;
            user-select: none;
}
.css-1hy9t21 {
    width: 100%;
    height: 100%;
    text-align: center;
    object-fit: cover;
    color: transparent;
    text-indent: 10000px;
}
.css-k7os9j {
    margin-left: 16px;
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(16,12,109);
}
.css-1k96qjc {
    margin: 0px;
    font-weight: 600;
    line-height: 1.57143;
    font-size: 0.875rem;
    font-family: "Public Sans", sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: 'blue';
}

.css-10n697b {
    margin: 0px;
    line-height: 1.57143;
    font-size: 0.875rem;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgb(99, 115, 129);
}

/* details cards mui*/
.css-81o1ld {
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: hidden;
    z-index: 0;
    position: relative;
    border-radius: 16px;
    box-shadow: rgb(145 158 171 / 48%) 0px 0px 1px 0px, rgb(145 158 171 / 24%) 0px 2px 4px -1px;
    display: flex;
    align-items: center;
    padding: 24px;
}
.css-i9gxme {
    flex-grow: 1;
}
.css-yemnbq {
    margin: 0px;
    font-weight: 600;
    line-height: 1.57143;
    font-size: 0.875rem;
    font-family: "Public Sans", sans-serif;
}

@media (min-width: 1200px)
{
    .css-dq3y6p {
        font-size: 1.2rem;
    }
}


@media (min-width: 900px)
{
    .css-dq3y6p {
        font-size: 1.275rem;
    }
}

@media (min-width: 600px)
{
    .css-dq3y6p {
        font-size: 1.25rem;
    }
}


.css-dq3y6p {
    margin: 0px;
    font-weight: 500;
    line-height: 1.5;
    font-size: 1.2rem;
    font-family: "Public Sans", sans-serif;
}
@media (min-width: 1200px)
{
    .css-19egsyp {
        flex-basis: 25%;
        flex-grow: 0;
        max-width: 25%;
        margin: 10px;
    }
}

/* eleement line */
.block {
    height: 60px;
    width:100%;
    display: grid;
    grid-template-columns: 1.5fr 80px 30fr;
    grid-template-rows: 1fr;
    background-color: rgb(255, 255, 255);
  }

  hr {
    margin: auto 20px;
    border: 1.5px solid rgb(4, 1, 1);
  }

  .hrClass{
    margin: 20px 20px;
    border: 1px dotted rgb(4, 1, 1);
  }

  .text {
    margin: auto 0;
    font-size: 22px;
    color: rgb(0, 0, 0);
  }


  /* spinner */
  .flexbox {
    display: block;
    margin: auto;
}

.flexbox>div {
    width: 100%;
    height: 100%;
    flex: 0 0 25%;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    margin: 0;
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.nb-spinner {
    width: 100px;
    height: 100px;
    margin: 0;
    background: transparent;
    border-top: 4px solid #009688;
    border-right: 4px solid transparent;
    border-radius: 50%;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
}

/* laoding  */

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);
    margin-left: 15%;
  }
  .wave {
    width: 10px;
    height: 300px;
    background: linear-gradient(360deg, #0d0d0d, #000);
    margin: 35px;
    -webkit-animation: wave 1s linear infinite;
            animation: wave 1s linear infinite;
    border-radius: 50px;
  }
  .wave:nth-child(2) {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
  }
  .wave:nth-child(3) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  .wave:nth-child(4) {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
  .wave:nth-child(5) {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  .wave:nth-child(6) {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  .wave:nth-child(7) {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }
  .wave:nth-child(8) {
    -webkit-animation-delay: 0.7s;
            animation-delay: 0.7s;
  }
  .wave:nth-child(9) {
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }
  .wave:nth-child(10) {
    -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
  }

  @-webkit-keyframes wave {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }

  @keyframes wave {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }

  /* pie */


/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge add Firefox */
  .scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }

/* input[type="file"] {
    display: none;
} */
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}


.containerLoading {
  height: 45em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: "relative";
}






.feedback {
    color: white;
    padding: 10px 20px;
    border-radius: 30px;
    margin:10px;
  }
  
  .mybutton {
    position: fixed;
    bottom: -4px;
    right: 10px;
    margin: 10px;
    padding: 10px;
  }


.feedback {
    color: white;
    padding: 10px 20px;
    border-radius: 30px;
    margin:10px;
  }
  
  .mybutton {
    position: fixed;
    bottom: -4px;
    right: 10px;
    margin: 10px;
    padding: 10px;
  }


/* .App {
    text-align: left;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
    color: #000133;
    text-align: left;
    display: flex;
    height: 100vh;
  } */


  .App {
    display: flex;
    height: 100vh;
    text-align: left;
    flex-direction: column;
  }

  .header,
  .footer {
    background: #00022e;
    height: 50px;
    color: #fc86aa;
  }

  .container {
    display: flex;
    flex: 1 1;
  }

  .drawer {
    /* background: #d8dcd6; rgb(61,108,48)*/
    width: 240px;
    background-color:white ;
    box-shadow: 0 0 6px hsl(210 14% 90%);
    margin-right:0px;

  }

  .main {
    flex: 1 1;
    background: rgba(248, 249, 252, 1);
    overflow-y: hidden;
  }

  .select-product {
    position: absolute;
    top: 95px;
    height: 40px;
    width: 140px;
    font-size: 16px;
  }






.btn {
  border: 2px solid rgb(21, 27, 128);
  background-color: white;
  color: rgb(21, 27, 128);
  padding: 5px 5px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
}
.btn:hover {
  border: 1px solid rgb(21, 27, 128);
  background-color: white;
  color: rgb(21, 27, 128);
  padding: 5px 5px;
  font-size: 16px;
  cursor: pointer;
}


.success {
  border-color: green;
  color: green;
}




  .link{
    display: flex;
    align-items: cover;
    cursor: pointer;
    border-radius: 30px;
    margin:5px 0px 5px 0px;
  }
  .link:hover{
    background-color: #F0F8FF;
    color: rgb(21, 27, 128);
    font-weight: 500;
    transition: background-color 100ms ease-out;
  }


/* SIDEBAR */

.sidebar {
  min-width: 240px;
  background-color: white;
  color:black ;
  box-shadow: 0 0 6px hsl(210 14% 90%);
  /* margin:0px 1px 0px 0px; */
}



.sidebar-closed {
  min-width: 80px;
  background-color: white;
  color:black ;
  transition: 0.4s ease-in;
}

.innerDiv {
  list-style-type: none;
  padding: 0;
  margin: 40px 10px 20px 10px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 70vh;
}

.innerDiv::-webkit-scrollbar {
  display: none;
}

.sidebar__listItem {
  display: flex;
  padding: 0px;
}


.sidebar__listItem span {
  white-space: nowrap;
  margin: 10px 0px 20px 15px;
}

.sidebar__icon {
  margin: 10px 0px 10px 15px;
  height:26px;
  width:26px;
}



/* hjdkfjeshflehsfhlwrhfe */






.info {
    border: 1px solid;
    margin: 20px;
    padding: 15px 10px 15px 50px;
    border-radius: 30px;
    max-width: 1080px;
    background-repeat: no-repeat;
    background-position: 10px center;
}
.info {
    color: #00529B;
    background-color: #BDE5F8;
    background-image: url('https://i.imgur.com/ilgqWuX.png');
}
.custom-select {
  font-size: 16px;
  border-radius: 4px;
  border-color: rgba(208, 208, 208, 1);
  min-width: 175px;
  background-color: white;
}

.custom-menu-item {
  font-size: 16px;
  padding: 8px;
  margin: 8px;
}
::-webkit-file-upload-button {
  background: none;
  border: none;
  color: rgba(17, 102, 238, 1);
  font-size: 18px;
}

progress {
  width: 100%;
  border-radius: 40px;
  height: 8px;
}

/* Chrome & Safari: Unfilled portion of the progress bar */
progress::-webkit-progress-bar {
  border-radius: 40px;
  background-color: rgba(232, 234, 244, 1);
  border-radius: 40px;
}

progress::-webkit-progress-value {
  border-radius: 40px;
  background-color: rgba(27, 45, 147, 1);
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: rgba(27, 45, 147, 1);
  --_m:
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  -webkit-animation: l3 1s infinite linear;
          animation: l3 1s infinite linear;
}
@-webkit-keyframes l3 {to{-webkit-transform: rotate(1turn);transform: rotate(1turn)}}
@keyframes l3 {to{-webkit-transform: rotate(1turn);transform: rotate(1turn)}}

@media only screen and (min-width: 1400px) {
  .full-container {
    margin: 0 15%;
  }
  #download-file {
    width: 340px;
  }
  #select-company {
    width: 340px;
  }
}

@media only screen and (min-width: 1101px) and (max-width: 1299px) {
  .full-container {
    margin: 0 8%;
  }
  #download-file {
    width: 260px;
  }
  #select-company {
    width: 260px;
  }
}

@media only screen and (min-width: 1300px) {
  .full-container {
    margin: 0 10%;
  }
  #download-file {
    width: 340px;
  }
  #select-company {
    width: 340px;
  }
}

@media only screen and (max-width: 1100px) {
  .full-container {
    margin: 0 5%;
  }
  #download-file {
    width: 220px;
  }
  #select-company {
    width: 220px;
  }
}
